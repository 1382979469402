.card-wrapper {
  @apply sm:px-2;
}

.card-wrapper-sticky-default {
  @apply sm:px-2;
  @apply sticky;
  @apply top-0 left-0 right-0;
  @apply flex items-center justify-center;
  @apply z-10;
}

.card-wrapper-sticky-e2 {
  @apply sticky;
  @apply bottom-0 left-0 right-0;
  @apply flex items-center justify-center;
  @apply z-10;
  @apply mt-auto;
}

.card-wrapper-sticky-e2-web {
  @apply fixed;
  @apply bottom-0 left-0 right-0;
  @apply flex items-center justify-center;
  @apply z-100;
  @apply mt-auto;
}

.card {
  @apply shadow-none;
  @apply bg-transparent;
  @apply drop-shadow-md;
  @apply mx-auto;
  @apply my-8;
  @apply w-full max-w-screen-sm;

  & .card-header {
    @apply bg-transparent;
    @apply flex;
    @apply p-0 pt-4;

    & svg {
      @apply w-7 h-7;
      @apply flex-shrink-0;
    }

    & .card-header-bg {
      @apply text-center;
      @apply bg-surface;
      @apply w-full h-7;
      @apply relative;
      @apply -mx-px;

      & .label {
        @apply transform -translate-y-1/2;
        z-index: 3;
      }
    }
  }

  & .card-content {
    @apply bg-surface;
    @apply rounded-b;
    @apply -mt-px;
    &.card-content-default {
      @apply px-4 pb-4 pt-2;
    }
    &.card-content-table {
      @apply px-2 py-4 xs:px-4;
    }
    &.card-content-match-row {
      @apply px-1 sm:px-6 pb-4;
      @apply relative;
      @apply text-center;
    }
    &.card-content-news {
      @apply px-2 pb-4;
    }
    &.card-content-e2 {
      @apply bg-default-color-forced-dark;
    }
  }

  & .card-content-wrapper {
    @apply p-4 pb-6;
    &.card-content-wrapper-table {
      @apply px-2 xs:px-4;
    }
  }

  &.card-transparent {
    @apply drop-shadow-none;
    & .card-content {
      @apply bg-transparent;
    }
  }
  &.card-no-padding {
    @apply my-0;
    & .card-content {
      @apply p-0;
    }
  }
  &.card-no-margin {
    @apply my-0;
    @apply mx-0;
    @apply max-w-full;
  }
}
